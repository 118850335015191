
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ArrowButton extends Vue {
  @Prop({ default: false }) disableButtons?: boolean;
  @Prop({ default: false }) preventArrow?: boolean;
  @Prop({ default: false }) hideButton?: boolean;
}
