var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"outer-wrapper fluid d-flex flex-column justify-center align-center pa-0 ma-0",style:(_vm.isLogin
      ? {
          backgroundImage:
            'url(' + require('@/assets/images/bg_bottom.svg') + ')',
        }
      : {})},[(_vm.isLogin)?_c('v-col',{staticClass:"waves pa-0 ma-0",style:({
      backgroundImage: 'url(' + require('@/assets/images/top.svg') + ')',
    }),attrs:{"cols":"12"}},[_c('p')]):_vm._e(),(!_vm.isLogin)?_c('AppHeader'):_vm._e(),_vm._t("default"),_c('ScrollUpButton'),_c('AdminFooter')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }