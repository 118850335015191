
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import router from '../../router';

@Component
export default class BaseFooter extends Vue {
  goToImprint(): void {
    router.push({
      name: 'impressum',
      params: { schoolName: getSchoolPrefix() },
    });
  }
}
