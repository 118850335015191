
import AppHeader from '../admin/header/AppHeader.vue';
import ScrollUpButton from '../base/ScrollUpButton.vue';
import Component from 'vue-class-component';
import AdminFooter from '../admin/base/AdminFooter.vue';
import SchoolMixin from './SchoolMixin';

@Component({
  components: { AdminFooter, ScrollUpButton, AppHeader },
  name: 'AdminLayout',
})
export default class AdminLayout extends SchoolMixin {
  get backgroundImage(): string {
    return require('@/assets/images/bg_top.svg');
  }

  get isLogin(): boolean {
    return (
      this.$route.name === 'school' ||
      this.$route.name === 'forgotPassword' ||
      this.$route.name === 'resetPassword' ||
      this.$route.name === 'imprint'
    );
  }
}
