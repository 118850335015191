
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  lettersAudios,
  fantasyAAudios,
  fantasyBAudios,
  findWordsImages_a,
  findWordsImages_b,
  Pathname,
} from '@/utils/helpers';
import { precacheFiles } from '@/utils/common';
import FantasyModule from '@/store/modules/FantasyModule';

@Component
export default class TutorialEndEmoji extends Vue {
  @Prop() audio?: string;
  @Prop({ default: false }) text?: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isTutorial?: boolean;
  @Prop() module?: SyllableModule | RiddleModule | LettersModule | WordsModule;

  userModule: UserModule = getModule(UserModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  hasImage = false;

  files: string[] = [];
  loadingAudios = false;

  get image(): string {
    return require('@/assets/images/lion_small.png');
  }

  get isSyllableTutorial() : boolean {
    return this.$route.path.includes(Pathname.syllables) && !!this.isTutorial;
  }


  setHasImage(): void {
    this.hasImage = true;
  }

  defineTestData(): void {
    const isLetters = this.$route.path.includes(Pathname.letters);
    const isFantasy = this.$route.path.includes(Pathname.fantasy);
    const isWords = this.$route.path.includes(Pathname.words);

    if (isLetters) {
      const testAudios = lettersAudios.map(
        (letter) => `/audio/letters/${letter}.mp3`,
      );
      this.files = [...testAudios];
    } else if (isFantasy) {
      const form = this.fantasyModule.currentForm;
      const formAudios = form === 'a' ? fantasyAAudios : fantasyBAudios;
      const testAudios = formAudios.map(
        (audio) => `/audio/fantasy/form-${form}/${audio}.mp3`,
      );
      this.files = [...testAudios];
    } else if (isWords) {
      const form = this.wordsModule.currentForm;
      const format = this.wordsModule.imageFormat;
      const formImages = form === 'a' ? findWordsImages_a : findWordsImages_b;
      const testImages = formImages.map(
        (image) => `/images/find-words/${image}.${format}`,
      );
      this.files = [...testImages];
    }
  }

  async mounted(): Promise<void> {
    // INFO preload relevant test data here!
    this.defineTestData();
    try {
      this.loadingAudios = true;
      await precacheFiles(this.files);
    } catch (error) {
      console.log('error', error);
    } finally {
      this.loadingAudios = false;
    }
  }

  start(): void {
    this.userModule.stopActiveAudio();
    this.$emit('start');
  }
}
