
import Vue from 'vue';
import Component from 'vue-class-component';
import router from '@/router';
import { Test } from '../../api/types';
import UserModule from '@/store/modules/UserModule';
import { getModule } from 'vuex-module-decorators';
import { getSchoolPrefix } from '@/utils/common';

@Component
export default class BaseHeaderBackButton extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);

  get isAuth(): boolean {
    return this.userModule.currentUser._id !== '';
  }

  get showButton(): boolean {
    return (
      this.$route.name === 'impressum' ||
      this.$route.name === Test.letters ||
      this.$route.name === Test.fantasy ||
      this.$route.name === Test.syllables ||
      this.$route.name === Test.words ||
      this.$route.name === Test.riddle
    );
  }

  action(): void {
    const name = this.$route.name;
    // INFO stop active audio when leaving
    this.userModule.stopActiveAudio();
    if (!this.isAuth) {
      router.push({ name: 'login', params: { schoolName: getSchoolPrefix() } });
    } else if (name === 'form') {
      router.push({ name: 'start', params: { schoolName: getSchoolPrefix() } });
    } else if (name === 'tests') {
      router.push({ name: 'form', params: { schoolName: getSchoolPrefix() } });
    } else if (
      name === 'impressum' ||
      name === Test.letters ||
      name === Test.fantasy ||
      name === Test.syllables ||
      name === Test.words ||
      name === Test.riddle
    ) {
      router.back();
    } else {
      router.push({
        name: 'tests',
        params: { schoolName: getSchoolPrefix() },
      });
    }
  }
}
