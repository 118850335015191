
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import FantasyModule from '../../store/modules/FantasyModule';
import LettersModule from '../../store/modules/LettersModule';
import RiddleModule from '../../store/modules/RiddleModule';
import SyllableModule from '../../store/modules/SyllableModule';
import WordsModule from '../../store/modules/WordsModule';
import BaseHeaderActionButton from './BaseHeaderActionButton.vue';
import BaseHeaderBackButton from './BaseHeaderBackButton.vue';

@Component({
  components: { BaseHeaderBackButton, BaseHeaderActionButton },
})
export default class GeneralHeader extends Vue {
  @Prop() title!: string;
  @Prop() subtitle?: string;

  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);

  dialog = false;
  isDisabled = false;

  get notHome(): boolean {
    return this.$route.path != '/';
  }

  get isStart(): boolean {
    return (
      this.$route.name === 'start' ||
      this.$route.name === 'tests' ||
      this.$route.name === 'form' ||
      this.$route.name === 'parentInfo' ||
      this.$route.name === 'congratulations'
    );
  }

  get isNotImprint(): boolean {
    return this.$route.name !== 'impressum';
  }

  get notCongratulations(): boolean {
    return this.$route.name != 'congratulations';
  }

  get isLogin(): boolean {
    return this.$route.name === 'login' || this.$route.name === 'info';
  }

  get isTest(): boolean {
    return this.$route.path.includes('test');
  }

  get icon(): string {
    const name = this.$route.name;

    if (name === 'start' || name === 'tests' || name === 'form') {
      return 'mdi-exit-to-app';
    } else {
      return 'mdi-close';
    }
  }

  playTestSound(): void {
    this.isDisabled = true;
    const sound = new Audio('/audio/tutorials/u1/HELP3833.mp3');
    sound.addEventListener(
      'ended',
      () => {
        this.isDisabled = false;
      },
      { once: true },
    );
    sound.play();
  }
}
