
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Test } from '../../api/types/api';
import BaseFooter from '../base/BaseFooter.vue';
import SchoolMixin from './SchoolMixin';

@Component({
  components: { BaseFooter },
  name: 'GeneralLayout',
})
export default class GeneralLayout extends SchoolMixin {
  @Prop() header?: string;

  get leaf(): string {
    return require('@/assets/images/palm_leaf.png');
  }

  get isSyllable(): boolean {
    return this.$route.name === Test.syllables;
  }

  get isLogin(): boolean {
    return this.$route.name === 'login';
  }

  get showLeaf(): boolean {
    let isOnTestRoute =
      this.$route.name === Test.letters ||
      this.$route.name === Test.fantasy ||
      this.$route.name === Test.syllables ||
      this.$route.name === Test.words ||
      this.$route.name === Test.riddle;
    return !isOnTestRoute;
  }
}
