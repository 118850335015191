
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';

@Component
export default class LoginInfoBar extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  get image(): string {
    return require('@/assets/images/lion_small.png');
  }

  get snackbar(): boolean {
    return this.snackbarModule.showSnackbar;
  }

  get message(): string {
    return this.snackbarModule.message;
  }

  get timeout(): number {
    return this.snackbarModule.timeout;
  }

  get color(): string {
    return this.snackbarModule.color;
  }
}
