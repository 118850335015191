
import router from '@/router';
import FantasyModule from '@/store/modules/FantasyModule';
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Client, Form, Test } from '../../api/types/api';
import SavingInfoBar from './SavingInfoBar.vue';

@Component({
  components: {
    SavingInfoBar,
  },
})
export default class TestEndEmoji extends Vue {
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  @Prop({ default: true })
  saving?: boolean;

  @Prop({ default: false })
  reachedMaxRetries?: boolean;

  @Prop({ default: false })
  saveSuccess?: boolean;

  @Prop()
  module?:
    | LettersModule
    | FantasyModule
    | SyllableModule
    | WordsModule
    | RiddleModule;

  path = this.$route.name!;
  loading = false;
  finalSuccess = false;

  get image(): string {
    return require('@/assets/images/lion_small.png');
  }

  get isRiddle(): boolean {
    return this.path === Test.riddle;
  }

  get isFindWords(): boolean {
    return this.path === Test.words;
  }

  get isTherapyClient(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  @Watch('saveSuccess', { immediate: true })
  onWatchError(): void {
    if (this.saveSuccess) {
      console.log('[TestEndEmoji] SUCCESS!!!');
      this.goToOverview();
    }
  }

  getEndSound(path: string): HTMLAudioElement {
    let sound: HTMLAudioElement;
    if (path === Test.letters) {
      sound = new Audio('/audio/toll.mp3');
    } else if (path === Test.fantasy) {
      sound = new Audio('/audio/grossartig.mp3');
    } else if (path === Test.syllables) {
      sound = new Audio('/audio/ausgezeichnet.mp3');
    } else {
      sound = new Audio('/audio/toll.mp3');
    }
    return sound;
  }

  mounted(): void {
    // INFO play sound on find words
    if (this.isFindWords) {
      const sound = this.getEndSound(this.path);
      sound.play();
    }
  }

  goToOverview(): void {
    const form = this.$route.params.form;
    const prefix = getSchoolPrefix();
    let timeInMs = 0;
    // INFO set time out to wait for sound and then move to the next
    if (this.path === Test.fantasy || this.path === Test.syllables) {
      timeInMs = 3000;
    } else if (this.isRiddle) {
      // INFO going to congratulation on riddle container - show overview with completed tests
      this.$router.push({ path: `/${prefix}/tests/${form}`, replace: true });
      return;
    } else {
      timeInMs = 6000;
    }
    console.log('[TestEndEmoji] going to overview');
    setTimeout(async () => {
      router.push({ path: `/${prefix}/tests/${form}`, replace: true });
    }, timeInMs);
  }

  async afterRiddle() {
    const prefix = getSchoolPrefix();
    const superAudio = new Audio('/audio/tutorials/u1/HELP3833.mp3');
    const congratulations = new Audio('/audio/gratuliere_alles.mp3');

    if (this.isTherapyClient) {
      try {
        await this.schoolModule.decreaseValidTests(this.schoolModule.slug);
        await this.schoolModule.getBasicSchoolInfo(this.schoolModule.schoolId);
      } catch (error) {
        console.log('[TEST END] could not fetch school info', error);
      }
    }
    const form = this.$route.params.form;
    congratulations.addEventListener(
      'ended',
      () => {
        setTimeout(async () => {
          const currentModule = this.module as RiddleModule;
          currentModule?.haveShownCongrats(form);
          router.replace({
            name: 'congratulations',
            params: { schoolName: prefix },
          });
          setTimeout(() => superAudio.play(), 1000);
        }, 2300);
      },
      { once: true },
    );
    congratulations.play();
  }

  async save(): Promise<void> {
    if (this.module && this.reachedMaxRetries) {
      try {
        this.loading = true;
        const res = await this.module.saveStatus(
          this.userModule.currentUser._id,
        );

        if (this.isRiddle) {
          const userId = this.userModule.currentUser._id;
          const updateData =
            this.module?.chosenForm === Form.a
              ? { doneA: true }
              : { doneB: true };
          await this.userModule.updateUser({ id: userId, data: updateData });
        }

        if (res?.status === 200) {
          this.finalSuccess = true;
          this.module.finishedAndSavedTest();
          this.snackbarModule.showMessage({
            message: this.$t('snackbar.test-end.save-success').toString(),
            isSuccess: true,
          });

          if (this.isRiddle) {
            await this.afterRiddle();
          }
          this.goToOverview();
        }
      } catch (error: any) {
        this.module.resetDone();
        console.error(
          '[SAVING TEST END] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
        this.snackbarModule.showMessage({
          message: this.$t('snackbar.test-end.save-error').toString(),
          isSuccess: false,
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
