import { render, staticRenderFns } from "./BaseFooter.vue?vue&type=template&id=694e5c8c&scoped=true&"
import script from "./BaseFooter.vue?vue&type=script&lang=ts&"
export * from "./BaseFooter.vue?vue&type=script&lang=ts&"
import style0 from "./BaseFooter.vue?vue&type=style&index=0&id=694e5c8c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "694e5c8c",
  null
  
)

export default component.exports