
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ScollUpButton extends Vue {
  scrollUp(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
